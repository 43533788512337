<template>
  <div class="card announcement">
    <a-modal class="modal-card-announcement" centered :width="1200" :visible="visibleDetail" @cancel="closeDetail"  :title="null" :footer="null">
      <DetailAnnouncement :contents="contents" :newData="{}" :classes="classes" :levels="levels" />
    </a-modal>
    <div class="card-header card-header-flex">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="h-100">
          <h2 class="font-size-18">Recent Announcement</h2>
        </div>
        <router-link :to="to"><div class="font-weight-bold" style="color: #041930;">View All</div></router-link>
      </div>
    </div>
    <div class="card-body py-4">
      <template v-if="announcementData.length">
        <div class="card-content" @click.prevent="showDetail(item)" v-for="(item, i) in announcementData" :class="i !== 0 ? 'mt-3' : null"
          :key="item.id">
          <CardAnnouncement :item="item" :role="role" />
          <!-- <div v-if="i !== announcementData.length - 1" class="ant-divider ant-divider-horizontal" role="separator" style="/* margin-bottom: 0px; */"/> -->
        </div>
      </template>
      <EmptyState
        v-else
        heading="No Data"
        description="There's no announcement for you."
      />
    </div>
  </div>
</template>

<script>
import { clearTag } from '@/helpers/regex.js'
import moment from 'moment'

const CardAnnouncement = () => import('./Card')
const EmptyState = () => import('@/components/app/EmptyState')
const DetailAnnouncement = () => import('@/components/app/DetailAnnouncement')

export default {
  components: { CardAnnouncement, EmptyState, DetailAnnouncement },
  data() {
    return {
      levels: [],
      classes: [],
      contents: {},
      visibleDetail: false,
    }
  },
  props: {
    announcementData: {
      type: Array,
    },
    to: {
      type: Object,
    },
  },
  methods: {
    clearTag,
    moment,
    showDetail(data) {
      this.visibleDetail = true
      console.log(data)
      this.contents = data
      if (!data.pengumuman_terbacas?.length && this.role === 'Student') {
        const payload = { id_announcement: data.id }
        this.$store.dispatch('announcement/UPDATE_STATUS_READ', payload)
      }
    },
    closeDetail() {
      this.contents = {}
      this.visibleDetail = false
      this.$emit('fetch-announcement')
    },
    async fetchLevels() {
      try {
        const data = await this.$store.dispatch('master/FETCH_LEVEL', { page: 'all' })
        this.levels = data.map(level => {
          return {
            nama: level.nama,
            id: level.id,
          }
        })
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchClasses() {
      try {
        const data = await this.$store.dispatch('admin/FETCH_CLASS', { page: 'all' })
        this.classes = data.map(kelas => {
          const { id } = kelas
          return {
            id,
            title: `${kelas.level.nama} - ${kelas.simbol}`,
          }
        })
        // console.log(data)
      } catch (err) {
        console.log(err)
      }
    },
  },
  computed: {
    role() {
      const role = this.$store.state.user.role
      if (role.includes('guruMapel')) {
        return 'Teacher'
      } else if (role.includes('murid')) {
        return 'Student'
      } else if (role.includes('admin')) {
        return 'Admin'
      } else {
        return 'Other'
      }
    },
  },
  created() {
  },
}
</script>

<style lang="scss">
.announcement {
  .card-announcement {
    transition: all 0.3s;
    border-radius: 10px;
    border: 0.5px solid #0419301a;
    // background-color: var(--kit-color-primary);
    &:hover {
      box-shadow: 0px 0px 20px #0419301a;
      transform: translate(0, -5px);
      cursor: pointer;
    }
    .meta { color: #9c9c9c; }

    &-unread {
      border: none;
      background-color: rgba(250, 173, 21, 0.1);

    }

    .title {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .hsn-icon {
      font-size: 22px;
      color: rgb(250, 173, 21);;
    }
  }
  .meta {
    font-size: 12px!important;
  }
  .info {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .modal-card-announcement {
    .title-detail {
      font-size: 20px;
      font-weight: 600;
      color: #303030
    }

    .meta {
      .meta-title {
        font-size: 13px!important;
        color: #9c9c9c;
        letter-spacing: 0.5px;
      }

      .meta-content {
        font-size: 13px!important;
        color: #2e2e2e;
      }
    }

    .ck-editor__editable {
      min-height: 300px;
    }
  }
}
</style>
